import DirectoryItem from '../directory-item/directory-item.component';

import { DirectoryContainer } from './directory.styles';

const categories = [
  {
    id: 1,
    title: 'anime',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSraVfPajVODSPsqfdbpkDKsK9-OkMHJPsmKjG5uzfok_T1eE-eT1MLS_6psT-n27Qhwns&usqp=CAU', //https://i.ibb.co/cvpntL1/hats.png
    route: 'shop/anime',
  },
  {
    id: 2,
    title: 'music',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3swt_M074ls2SS8qZ2AhsFgnUwzd4TwXbrzpvJWZPto58-68WPcpX-9mxpTWiJiCK7TU&usqp=CAU', //
    route: 'shop/band',
  },
  {
    id: 3,
    title: 'films',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_cwvBq6UqDmRWlZvz3AdkLT8kTmHzRurdqQ&usqp=CAU', //https://i.ibb.co/0jqHpnp/sneakers.png
    route: 'shop/films',
  },
  {
    id: 4,
    title: 'game',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTS9fOy4tJNzK8R7c4-ujUhwH7RlerrqGxiqw&usqp=CAU', //https://i.ibb.co/GCCdy8t/womens.png',
    route: 'shop/game',
  },
  {
    id: 5,
    title: 'pop culture',
    imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVAwrPXNOJZ6o8fV8MkZcEoM1xOIX1D8N0vquuMZPb9n_wMHBfM_KU1pxuYBYQCazigrA&usqp=CAU', //'https://i.ibb.co/R70vBrQ/men.png',
    route: 'shop/pop culture',
  },
];

const Directory = () => {
  return (
    <DirectoryContainer>
      {categories.map((category) => (
        <DirectoryItem key={category.id} category={category} />
      ))}
    </DirectoryContainer>
  );
};

export default Directory;
